import styled from 'styled-components'

const ButtonStyle = styled.div.attrs((props) => ({}))`
	--bigButtonArrowSizes: var(--sp8x);
	--bigButtonArrowMarginLeft: var(--sp6x);

	--buttonArrowSizes: var(--sp4x);
	--buttonArrowMarginLeft: var(--sp3x);

	button,
	a {
		position: relative;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		color: var(--primaryColor1);
		background-color: unset;
		border: unset;
		transition: all var(--trTime);
		-webkit-appearance: none;
		cursor: pointer;
		padding: 0;

		@media (hover: hover) {
			&:hover {
				.comp-title {
					&:before {
						width: 100%;
					}
				}
			}
		}

		.comp-title {
			position: relative;
			text-transform: uppercase;
			font-family: Montserratarm;
			font-weight: 500;
			font-size: var(--p2);
			line-height: var(--lineHeightS);

			&:before {
				content: '';
				position: absolute;
				top: 100%;
				left: 0;
				display: block;
				height: 1px;
				width: 0;
				background-color: var(--primaryColor1);
				transition: var(--trTime);
			}
		}
	}

	.btn-arrow {
		font-size: var(--buttonArrowSizes);
		margin-left: var(--buttonArrowMarginLeft);
	}

	&.big {
		.comp-title {
			font-size: var(--h4);
			line-height: var(--lineHeightS);

			button {
				font-size: var(--h4);
				line-height: var(--lineHeightS);
			}
		}

		.btn-arrow {
			font-size: var(--bigButtonArrowSizes);
			margin-left: var(--bigButtonArrowMarginLeft);
		}
	}

	@media (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeL}) and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXLMin}) {
		--bigButtonArrowSizes: var(--sp7x);
		--bigButtonArrowMarginLeft: var(--sp5x);
		--buttonArrowSizes: var(--sp3x);
		--buttonArrowMarginLeft: var(--sp2x);
	}

	@media (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeM}) and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeLMin}) {
		--bigButtonArrowSizes: var(--sp6x);
		--bigButtonArrowMarginLeft: var(--sp4x);
		--buttonArrowSizes: var(--sp2x);
		--buttonArrowMarginLeft: var(--sp2x);
	}

	@media (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeS}) and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeMMin}) {
		--bigButtonArrowSizes: var(--sp5x);
		--bigButtonArrowMarginLeft: var(--sp4x);
		--buttonArrowSizes: var(--sp2x);
		--buttonArrowMarginLeft: var(--sp2x);
	}

	@media (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXS}) and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeSMin}) {
		--bigButtonArrowSizes: var(--sp4x);
		--bigButtonArrowMarginLeft: var(--sp4x);
		--buttonArrowSizes: var(--sp2x);
		--buttonArrowMarginLeft: var(--sp2x);
	}

	@media (min-width: ${({ theme }) => theme.mediaQuery.TabletSize}) and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXSMin}) {
		--bigButtonArrowSizes: var(--sp3x);
		--bigButtonArrowMarginLeft: var(--sp3x);
		--buttonArrowSizes: var(--sp2x);
		--buttonArrowMarginLeft: var(--sp2x);
	}

	@media (min-width: ${({ theme }) => theme.mediaQuery.TabletSizeS}) and (max-width: ${({ theme }) => theme.mediaQuery.TabletSizeMin}) {
		--bigButtonArrowSizes: var(--sp3x);
		--bigButtonArrowMarginLeft: var(--sp2x);
		--buttonArrowSizes: var(--sp2x);
		--buttonArrowMarginLeft: var(--sp2x);

		.comp-title {
			line-height: 1;
		}
	}

	@media (max-width: ${({ theme }) => theme.mediaQuery.TabletSizeSMin}) {
		--bigButtonArrowSizes: var(--sp2x);
		--bigButtonArrowMarginLeft: var(--sp2x);
		--buttonArrowSizes: var(--sp2x);
		--buttonArrowMarginLeft: var(--sp2x);

		.comp-title {
			line-height: 1;
		}
	}
`
export default ButtonStyle
