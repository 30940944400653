import { memo as Memo } from 'react'

//* Helper
import { config } from 'helpers'

//* Components
import Page from 'components/common/Page'
import Text from 'components/common/Text'
import Button from 'components/common/Button'
import Container from 'components/common/Container'

//* Style
import Custom404Style from 'styles/pages/Custom404Style'

const Custom404 = Memo(() => {
	return (
		<Page meta={{ title: 'AAB Construction | 404' }} className={`page-404`}>
			<Custom404Style>
				<Container>
					<div className={`error-content-wrapper`}>
						<Text tag={`h1`} className={`h2 primaryColor3 font-montserratarm-medium text-404`} text={`404`} />

						<Text tag={`h4`} className={`h4 primaryColor1 font-montserratarm-regular error-title`} text={`404Title`} />

						<div className={`error-text-wrapper`}>
							<Text tag={`p`} className={`p3 primaryColor1 font-montserratarm-regular error-text`} text={`404Desc`} />
						</div>

						<Button text={`homePage`} className={`btn-wrap`} url={config.routes.home.path} />
					</div>
				</Container>
			</Custom404Style>
		</Page>
	)
})

export default Custom404
