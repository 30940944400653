import { memo as Memo, useMemo, useEffect } from 'react'
import { useRouter } from 'next/router'
import { NextSeo } from 'next-seo'

const Page = Memo(({ meta, nextSeoProps, ...props }) => {
	//! Router
	const router = useRouter()

	//! URL
	const url = useMemo(() => process.env.host + (router.locale !== 'en' ? '/' + router.locale : '') + router.asPath, [router])

	useEffect(() => {
		window.history.scrollRestoration = 'manual'
	}, [])

	//! Description
	const description = useMemo(() => meta?.description || 'meta description', [meta?.description])

	//! Title
	const title = useMemo(() => meta?.title || 'meta title', [meta?.title])

	return (
		<>
			<NextSeo
				title={title}
				description={description}
				canonical={url}
				type='website'
				additionalMetaTags={[
					{
						name: 'keywords',
						content: meta?.keywords,
					},
				]}
				openGraph={{
					url: url,
					title: title,
					description: description,
					images: [{ url: meta?.image?.url || process.env.host + '/images/shape1.png', alt: meta?.image?.alt }],
				}}
				twitter={{
					handle: '@handle',
					site: '@site',
					cardType: 'summary_large_image',
				}}
				{...nextSeoProps}
			/>
			<main className={`page ${props.className || ''}`}>{props.children}</main>
		</>
	)
})

export default Page
