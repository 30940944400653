import styled from 'styled-components'

const Custom404Style = styled.section`
	--errorWrapMaxWidth: var(--sp193x);
	--lineDistance: var(--sp7x);
	--errorTextMaxWidth: var(--sp94x);
	--btnMTop: var(--sp19x);
	--errorWrapMTop: var(--sp14x);

	> .container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-top: var(--errorWrapMTop);
	}

	.error-content-wrapper {
		width: var(--errorWrapMaxWidth);
		text-align: center;

		.text-404 {
			padding-bottom: var(--lineDistance);
			border-bottom: 1px solid var(--primaryColor1);
		}

		.error-title {
			padding: var(--lineDistance) 0;
			border-bottom: 1px solid var(--primaryColor1);
		}

		.error-text-wrapper {
			padding-top: var(--lineDistance);
			display: flex;
			justify-content: center;
			align-items: center;

			.error-text {
				width: var(--errorTextMaxWidth);
			}
		}

		.btn-wrap {
			margin-top: var(--btnMTop);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeL}) {
		--errorWrapMaxWidth: var(--sp144x);
		--lineDistance: var(--sp6x);
		--errorTextMaxWidth: var(--sp70x);
		--btnMTop: var(--sp12x);
		--errorWrapMTop: var(--sp10x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		--errorWrapMaxWidth: var(--sp111x);
		--lineDistance: var(--sp6x);
		--errorTextMaxWidth: var(--sp54x);
		--btnMTop: var(--sp13x);
		--errorWrapMTop: var(--sp10x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		--errorWrapMaxWidth: var(--sp111x);
		--lineDistance: var(--sp5x);
		--errorTextMaxWidth: var(--sp54x);
		--btnMTop: var(--sp10x);
		--errorWrapMTop: var(--sp10x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXS}) {
		--errorWrapMaxWidth: var(--sp99x);
		--lineDistance: var(--sp5x);
		--errorTextMaxWidth: var(--sp73x);
		--btnMTop: var(--sp12x);
		--errorWrapMTop: var(--sp10x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		--errorWrapMaxWidth: var(--sp79x);
		--lineDistance: var(--sp5x);
		--errorTextMaxWidth: var(--sp58x);
		--btnMTop: var(--sp12x);
		--errorWrapMTop: var(--sp14x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSizeS}) {
		--errorWrapMaxWidth: var(--sp74x);
		--lineDistance: var(--sp7x);
		--errorTextMaxWidth: var(--sp58x);
		--btnMTop: var(--sp20x);
		--errorWrapMTop: var(--sp24x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--errorWrapMaxWidth: 100%;
		--lineDistance: var(--sp8x);
		--errorTextMaxWidth: 100%;
		--btnMTop: var(--sp20x);
		--errorWrapMTop: var(--sp16x);

		.error-content-wrapper {
			padding: 0 var(--sp4x);
		}
	}
`

export default Custom404Style
