import React, { useMemo, forwardRef, useImperativeHandle, useRef } from 'react'

//* HOCs
import { withLanguageContext } from 'context'
import withUIContext from 'context/consumerHOC/UIConsumer'

//* Components
import Icon from 'components/common/Icon'
import Text from 'components/common/Text'
import CustomLink from 'components/common/CustomLink'

//* Style
import ButtonStyle from './style'

const Button = forwardRef(({ size, url, customLinkProps, children, text, className, target }, ref) => {
	//! Refs
	const contRef = useRef()

	useImperativeHandle(ref, () => contRef.current, [])

	//! Checkers
	const Component = useMemo(() => (url ? CustomLink : 'button'), [url])
	const customProps = useMemo(() => (url ? { ...customLinkProps, target, url } : {}), [])

	return (
		<ButtonStyle ref={contRef} className={`${size || ''} ${className || ''}`}>
			<Component {...customProps}>
				{children || <Text text={text} />}
				<Icon name={'arrow'} className={'btn-arrow'} />
			</Component>
		</ButtonStyle>
	)
})
export default withUIContext(withLanguageContext(Button, ['translate', 'selectedLang']), ['winWidth', 'screenSizes'])
